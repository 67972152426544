import { FirebaseConfig } from "./firebase-config.interface";

// export const FirebaseLocalIgnore: FirebaseConfig = {
//   apiKey: "AIzaSyA5NVXkJuda0vM4J-h0NAbBfahHCdvYcZY",
//   authDomain: "dakoda-willden.firebaseapp.com",
//   databaseURL: "https://dakoda-willden.firebaseio.com",
//   projectId: "dakoda-willden",
//   storageBucket: "dakoda-willden.appspot.com",
//   messagingSenderId: "196553038973",
//   appId: "1:196553038973:web:1d8bfd9149d999f9b1a514",
//   measurementId: "G-6XNL71K1L4"
// }
export const FirebaseLocalIgnore: FirebaseConfig = {
  apiKey: "AIzaSyBkRDi2JsyIKrXqGAnjZotnZGAug1Ua1Io",
  authDomain: "dakoda-willden-old-portf-f53a5.firebaseapp.com",
  projectId: "dakoda-willden-old-portf-f53a5",
  storageBucket: "dakoda-willden-old-portf-f53a5.appspot.com",
  messagingSenderId: "640217040437",
  appId: "1:640217040437:web:f3a2840b7aadb15c08d37c",
  measurementId: "G-PF4FSKCT6S"
}
