<div class="navbar">
    <a class="clickable" (click)="openNav()">&#9776; Projects</a>
</div >

<div id="myNav" class="overlay" *ngIf="showNavOptions">
    <a class="closebtn" style="cursor: pointer;" (click)="closeNav()">&times;</a>
    <div class="overlay-content">
        <a routerLink="/Profile" (click)="closeNav()">Dakoda's Profile</a>
        <div>
            <a class="clickable" (click)="showHideBudgetOptions()">
                <b>Budget</b>
            </a>
            <div class="sub-content" *ngIf="showBudgetSubOptions">
                <a routerLink="/BudgetTest" (click)="closeNav()">Budget JQuery</a>
                <a routerLink="/BudgetTest" (click)="closeNav()">Budget API (Code not up yet)</a>
                <a routerLink="/BudgetTest" (click)="closeNav()">Budget Angular(Code not up yet)</a>
            </div>
        </div>
        <a routerLink="/Inventory" (click)="closeNav()">Inventory</a>
        <a routerLink="/Mobile" (click)="closeNav()">WGU Mobile App</a>
        <a routerLink="/Appointment-Scheduler" (click)="closeNav()">Appointment Scheduler</a>
        <div>
          <a class="clickable" (click)="showHideMoreOptions()">
              <b>More</b>
          </a>
          <div class="sub-content" *ngIf="showMoreSubOptions">
            <a *ngFor="let route of moreOptions$ | async" routerLink="/GitHub-Integration/{{route}}" (click)="closeNav()">{{ route }}</a>
          </div>
      </div>
    </div>
</div>
