<ng-container *ngIf="routedRepo$ | async as repo">
  <div class="bodyContent">
    <app-profile-summary
      title="{{ repo.Name }}"
      firstSectionText="Technologies Used: {{ technologies$ | async }}"
      secondSectionText="{{ repo.Description }}"
      linkToCode="{{ repo.LinkToCode }}"
      linkToWebsite="{{ repo.LinkToSite }}"></app-profile-summary>
    <div class="footerBuffer"></div>
  </div>
</ng-container>
