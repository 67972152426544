<h2>Using the Application</h2>
<br/>
<h3>Login</h3>
<p>1. Navigate to built website. Currently there is not a live version running.</p>
<!-- <p>1.   <b>To get to the Live Site: </b>Navigate to <a href="https://dakoda-willden.ddns.net/Projects/Inventory/WGUCapstone/" target="_blank">https://dakoda-willden.ddns.net/Projects/Inventory/WGUCapstone/</a>
     in a web browser. If you try to navigate to it by clicking the link, a popup will show up before opening the browser to access
     the website. It may pop up a couple of times before the browser opens, then another in the browser. For the login information,
     read the next step.</p> -->
<img src="~/../assets/Images/InventoryEnterPassword.png" class="ExamplePictures">
<p>2.	You will enter a username and password. The test account that is meant for evaluation purposes has the following credentials:</p>
<p><b>The Following Information will be needed to access the site:</b></p>
<p><b>Username:</b> testUser</p>
<p><b>Password:</b> C1$gaD05</p>
<br/>
<p>3.	A few seconds after you have entered the given credentials, and you are logged in, it should show the following page:</p>
<img src="~/../assets/Images/InventoryOverview.png" class="ExamplePictures">
<br/>
<br/>
<h3>How to Add An Asset</h3>
<img src="~/../assets/Images/InventoryClickAddAsset.png" class="ExamplePictures">
<p>1.	Click on the green ‘Add Asset’ button in the grey bar above the table</p>
<p>2.	A form should come up that asks you to fill in some information.</p>
<img src="~/../assets/Images/InventoryOpenAddAssetForm.png" class="ExamplePictures">
<br/>
<p>3. In this form, the left column is the information specific to the asset itself that doesn’t change based on assignment. The right column is the information relevant only to the current assignment. The minimum information that should be added is shown below.:</p>
<p class="AlignLeft">a.	An ‘Asset Id’ </p>
<p class="AlignLeft">b.	The date the asset was purchased</p>
<p class="AlignLeft">c.	The ‘Asset Type’</p>
<p class="AlignLeft">d.	The ‘Asset Brand’</p>
<p class="AlignLeft">e.	The ‘Asset Model’ (If the ‘Asset Model’ you are wanting to add doesn’t exist, you can add a new ‘Asset Model’. The instructions are in the ‘How to Add an Asset Model’ section a few sections below this one)</p>
<p class="AlignLeft">f.	The ‘Owner’ (This indicates the person it will be checked out to. If the ‘Owner’ you are wanting to add doesn’t exist, you can add a new ‘Owner’. The instructions are in the ‘How to Add an Owner’ section a couple sections below this one)</p>
<p class="AlignLeft">g.	The ‘Device Name’</p>
<p class="AlignLeft">h.	The ‘Location’ (If it will be in the office building, or at home for use)</p>
<p class="AlignLeft">i.	The date it is being checked out (‘Date Deployed’)</p>
<p class="AlignLeft">j.	NOTE: If this information is not given, it won’t allow you to submit the form, or will alert the user that the required information isn’t entered.</p>
<br/>
<img src="~/../assets/Images/InventoryOpenAddAssetFormErrors.png" class="ExamplePictures">
<p class="AlignLeft">4. Press ‘Submit’ when the required information as well as the information that you are wanting to track is included correctly in the form.</p>
<p class="">a.	NOTE: If the asset id already exists, the form will update the asset id mentioned</p>
<p class="AlignLeft">5.	The user will be alerted that the asset has been updated and will continue to an ‘Update Asset’ form. Click on the grey area, or the red cancel button to exit the form and get back to the main table.</p>
<p class="AlignLeft">6.	The table should be updated with the new asset</p>
<br/>
<br/>
<h3>How to Update An Asset</h3>
<p>Here are the steps for updating an asset:</p>
<p class="AlignLeft">1.	Click on the ‘Asset Id’ in the table for the asset you are wanting to edit the information for, and an ‘Update Asset’ form will show up, including the information specific to the asset, or the information specific to that assignment.</p>
<br/>
<img src="~/../assets/Images/InventoryClickUpdateAsset.png" class="ExamplePictures">
<br/>
<p class="AlignLeft">2.	When you click on the asset number you’re going to edit or update, a form like the Add Asset form will come up, and provide you with the information that’s currently saved, as well as the history for that specific asset. You may need to scroll down to view the history for that specific asset</p>
<br/>
<img src="~/../assets/Images/InventoryOpenUpdateForm.png" class="ExamplePictures">
<br/>
<p class="AlignLeft">3.	You can change the owner, and change the date deployed to create a new assignment, and set the old assignment to checked in, so the information in that assignment that is still quickly available would be in the history table at the bottom of the form</p>
<p class="AlignLeft">4.	Once you change any information you are wanting to change for an asset or its current assignment, press the green update button in the form, or press enter while in any other part of the form.</p>
<p class="AlignLeft">5.	You can choose to mark an asset as returned as well by setting the date returned value to when it was returned, and it will mark it as returned.</p>
<p>a.	NOTE: If you do this, in the main table, it will show the last person it was assigned to.</p>
<p class="AlignLeft">6.	When you press the update button, it will notify you when it has been updated, and update the main table.</p>
<br/>
<br/>
<h3>How to Add an Owner</h3>
<p class="AlignLeft">1.	Click on the dropdown that says ‘Add New’</p>
<img src="~/../assets/Images/InventoryAddDropdown.png" class="ExamplePictures">
<br/>
<p class="AlignLeft">2.	Click on the option that displays ‘Add New Owner’</p>
<p class="AlignLeft">3.	A form will pop up to add information for a new owner to be added</p>
<img src="~/../assets/Images/InventoryAddOwnerForm.png" class="ExamplePictures">
<p class="AlignLeft">4.	Enter the owner name or location name.</p>
<p class="AlignLeft">5.	Enter the username or details.</p>
<p class="AlignLeft">6.	Select the department you want the user or location to be a part of.</p>
<p class="AlignLeft">7.	Press the ‘Submit’ button.</p>
<br/>
<br/>
<h3>How to Add an Asset Model</h3>
<p class="AlignLeft">1.	Click on the dropdown that displays ‘Add New’.</p>
<img src="~/../assets/Images/InventoryAddAssetModelDropdown.png" class="ExamplePictures">
<p class="AlignLeft">2. Click on the option that says ‘Add New Asset Model’</p>
<p class="AlignLeft">3. A form should pop up to add a new ‘Asset Model’.</p>
<img src="~/../assets/Images/InventoryAddAssetModelForm.png" class="ExamplePictures">
<p class="AlignLeft">4. Enter the type and brand you want the new ‘Asset Model’ to tie to</p>
<p class="AlignLeft">5. Type in the new ‘Asset Model’ name.</p>
<p class="AlignLeft">6. Press ‘Submit’.</p>
<br/>
<br/>
<h3>Sort The Information</h3>
<p class="AlignLeft">You can do a combination of dropdowns to sort what information shows up on the main table to find information that you are needing to find. I will include the options you can sort by below:</p>
<p class="AlignLeft">1.	Brand</p>
<p class="AlignLeft">2.	Type</p>
<p class="AlignLeft">3.	Asset Model (Selecting brand and type dropdowns first change the options in this dropdown)</p>
<p class="AlignLeft">4.	Department</p>
<p class="AlignLeft">5.	Owner</p>
<p class="AlignLeft">6.	Location</p>
<p class="AlignLeft">7.	Search Bar</p>
<p class="AlignLeft">
    You can sort through the data using either using a combination of all the dropdowns, or through the search bar. If you have sorted it by dropdowns, and search by something more specific, the dropdowns are disregarded.
</p>
<p class="AlignLeft">
    Another thing you can do with sorting, is use the dark blue dropdown. That dropdown is used to determine which subset of data we are searching through. You can search through only disposed assets, only assets that are checked in, only assets that are checked out, all assets that are active, or all assets, regardless of checkout status, or active status.
</p>
<img src="~/../assets/Images/InventoryAllAssetsDropdown.png" class="ExamplePictures">
<br/>
<br/>
<h3>Download Reports</h3>
<p class="AlignLeft">1.	To download a report, sort the information so that the table is showing what you would like for it to show.</p>
<p class="AlignLeft">2.	Click the download report button.</p>
<p class="AlignLeft">3. When you press this, you will be able to download a .csv file for the report you just sorted to find</p>
<img src="~/../assets/Images/InventoryDownloadReportButton.png" class="ExamplePictures">
<br/>
